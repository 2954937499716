.ant-form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .ant-form-item {
        margin: 0;
        .ant-row {
            width: 100%;
        }

        .ant-form-item-label {
            padding: 0;
            margin-bottom: 0;
            label {
                color: $color--text1;
                @include font-14;
                font-family: $font-semibold;
                flex-direction: row-reverse;
                gap: 4px;

                &::after {
                    display: none;
                }
            }
        }

        .ant-form-item-explain-error {
            @include font-12;
            font-family: $font-regular;
            color: $color--red;
        }
    }
}

.ant-btn {
    box-shadow: none;
    @include font-12;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    .ant-btn-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
    }
}
